import React from "react";
import { ourWorkData } from "../../constants/Data";
import picture from "../../images/AdobeStock_192828957.jpeg";
import picture1 from "../../images/AdobeStock_58207592.jpeg";


const OurWork = () => {
  return (
    // <div>
    //   <div className="w-full bg-white">
    //     <div className="flex flex-col md:flex-row">
    //       <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
    //         <h1 className="text-black text-xl title pb-6">Data Services</h1>
    //         <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
    //         <p className="text-primary text-base  pb-4 title">
    //           Professional Services: At Astra Intel, we specialize in building
    //           and enhancing data infrastructure tailored to your organization's
    //           unique needs. Our process begins with a thorough assessment of
    //           your goals, ensuring that the solution we deploy aligns perfectly
    //           with your operational requirements. We don't just deliver a
    //           product; we empower your team with a ready-to-use solution from
    //           day one, complete with training and ongoing support.
    //         </p>
    //         <h1 className="text-black text-xl title pb-6">Managed Services</h1>

    //         <p className="text-primary text-base  pb-4 title">
    //           For organizations seeking continuous support, our managed services
    //           offer the flexibility you need. Whether you require a dedicated
    //           data administrator, proactive monitoring, or additional security
    //           oversight, we customize our services to match your specific needs.
    //           With adaptable billing options, you can choose the level of
    //           service that best fits your budget and objectives, ensuring you
    //           receive the support you need without overcommitting resources.
    //         </p>
    //         <h1 className="text-black text-2xl title title pb-3 ">
    //           Contract Assistant
    //         </h1>
    //         <p className=" h-1 bg-blue-500 w-8 mb-8"></p>

    //         <p className="text-primary text-base  pb-4 title">
    //           Simplify your legal processes with AI by receiving detailed
    //           summaries and comparisons of technical documents, saving you time
    //           and reducing the need for constant legal consultations. For
    //           example:
    //         </p>
    //         <li className="text-primary text-base  pb-4 title">
    //           "Summarize the lease terms for my rental contract."
    //         </li>
    //         <li className="text-primary text-base  pb-4 title">
    //           "What are the differences between drafts 1 and 2 of this city
    //           ordinance?"
    //         </li>
    //         <p className="text-primary text-base  pb-4 title">
    //           By joining our beta program, you’ll have the opportunity to be an
    //           early adopter of these innovative AI solutions at special
    //           introductory pricing. With Astra Intel, you’re not just
    //           integrating AI—you’re revolutionizing the way your organization
    //           harnesses data.
    //         </p>
    //       </div>
    //       <div className="mx-4 md:mx-0">
    //         <img src={picture} alt="picture" />
    //       </div>
    //     </div>

    //     <div className="flex flex-col md:flex-row">
    //       <div className="mx-4 md:mx-0">
    //         <img src={picture} alt="picture" />
    //       </div>
    //       <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
    //         <h1 className="text-black text-xl title pb-6">
    //           AI-Driven Solutions
    //         </h1>
    //         <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
    //         <p className="text-primary text-base  pb-4 title">
    //           Astra Intel is leading the charge in AI innovation, offering an
    //           exclusive beta program for 2024 that provides clients with access
    //           to cutting-edge AI tools tailored specifically to their needs.
    //         </p>
    //         <h1 className="text-black text-xl title pb-6">Managed Services</h1>

    //         <p className="text-primary text-base  pb-4 title">
    //           Our AI-driven solutions are designed to enhance productivity and
    //           deliver measurable results from day one. We have already
    //           implemented a range of groundbreaking AI products for our clients,
    //           including
    //         </p>
    //         <div className="mb-10">
    //           <h1 className="text-black text-xl font-semibold  pb-3">
    //             Virtual Campaign Team
    //           </h1>
    //           <p className="text-primary text-base  pb-8 title">
    //             Elevate your political campaign with our comprehensive AI-driven
    //             Virtual Campaign Team. This suite of tools empowers you to run a
    //             full-scale campaign with precision and efficiency, leveraging AI
    //             to handle key tasks:
    //           </p>
    //           <li>
    //             {" "}
    //             AI Data Director
    //             <p className="text-primary text-base  pb-4 title">
    //               Centralizes and analyzes your campaign data to inform strategy
    //               and optimize decisions.
    //             </p>{" "}
    //           </li>
    //           <li>
    //             AI Social Media Manager
    //             <p className="text-primary text-base  pb-4 title">
    //               {" "}
    //               Crafts and schedules engaging social media content, boosting
    //               your online presence and expanding your reach.
    //             </p>{" "}
    //           </li>
    //           <li>
    //             AI Speech Writer
    //             <p className="text-primary text-base  pb-4 title">
    //               Generates persuasive speeches and talking points tailored to
    //               your campaign’s message, ensuring consistency and impact.
    //             </p>
    //           </li>
    //           <li>
    //             AI Opposition Researcher
    //             <p className="text-primary text-base  pb-4 title">
    //               Conducts thorough research on opponents to inform your
    //               strategy, giving you a competitive edge.
    //             </p>
    //           </li>
    //           <li>
    //             AI Donor Manager
    //             <p className="text-primary text-base   title">
    //               Identifies and engages potential donors, maximizing your
    //               fundraising efforts and ensuring financial sustainability.
    //             </p>
    //           </li>
    //         </div>
    //       </div>
    //     </div>

    //     <div className="max-w-7xl mx-auto px-10 md:px-20 pt-20">
    //       <div className="mb-10">
    //         <h1 className="text-black text-xl font-semibold  pb-3 text-center">
    //           Marketing AI for Tailored Content
    //         </h1>
    //         <p className="text-primary title text-base  pb-4">
    //           Move beyond generic AI tools like ChatGPT. Our AI can be trained
    //           on your specific content, enabling your marketing team to generate
    //           highly personalized and effective communications. For example:
    //         </p>
    //         <li className="text-primary title text-base  pb-4">
    //           "Write an email subject line based on last quarter’s
    //           best-performing emails."
    //         </li>
    //         <li className="text-primary title text-base  pb-4">
    //           "Give me ten ideas for new social media posts based on this week’s
    //           real estate news."
    //         </li>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // ----------------------- 2nd variation -----------------------

    <div>
      <div className="w-full bg-white">
        <div className="flex flex-col md:flex-row">
          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">Data Services</h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary text-base  pb-4 title">
              <h1 className="text-black text-xl title pb-6">
                Professional Services
              </h1>
              At Astra Intel, we specialize in building and enhancing data
              infrastructure tailored to your organization's unique needs. Our
              process begins with a thorough assessment of your goals, ensuring
              that the solution we deploy aligns perfectly with your operational
              requirements. We don't just deliver a product; we empower your
              team with a ready-to-use solution from day one, complete with
              training and ongoing support.
            </p>
            <h1 className="text-black text-xl title pb-6">Managed Services</h1>

            <p className="text-primary text-base  pb-4 title">
              For organizations seeking continuous support, our managed services
              offer the flexibility you need. Whether you require a dedicated
              data administrator, proactive monitoring, or additional security
              oversight, we customize our services to match your specific needs.
              With adaptable billing options, you can choose the level of
              service that best fits your budget and objectives, ensuring you
              receive the support you need without overcommitting resources.
            </p>
          </div>
          <div className="mx-4 md:mx-0">
            <img
              style={{ height: "550px", width: "750px", objectFit: "cover" }}
              src={picture}
              alt="picture"
            />
          </div>
        </div>

        {/* ----------------------- AI-Driven Solutions ----------------------- */}

        <div className="flex flex-col md:flex-row pb-8">
        <div className="mx-4 md:mx-0">
  <img
    style={{ height: "350px", width: "750px", objectFit: "cover" }}
    src={picture1}
    alt="picture"
    className="rounded-l-lg"
  />
</div>

          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">
              AI-Driven Solutions
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary text-base  pb-4 title">
              Astra Intel is leading the charge in AI innovation, offering an
              exclusive beta program for 2024 that provides clients with access
              to cutting-edge AI tools tailored specifically to their needs.
            </p>
            {/* <h1 className="text-black text-xl title pb-6">Managed Services</h1> */}

            <p className="text-primary text-base  pb-4 title">
              Our AI-driven solutions are designed to enhance productivity and
              deliver measurable results from day one. We have already
              implemented a range of groundbreaking AI products for our clients,
              including
            </p>
          </div>
        </div>

        {/* -------------------  Virtual Campaign Team ------------------- */}

        {/* <div className="flex flex-col md:flex-row">
          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">
              Virtual Campaign Team
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary text-base  pb-8 title">
              Elevate your political campaign with our comprehensive AI-driven
              Virtual Campaign Team. This suite of tools empowers you to run a
              full-scale campaign with precision and efficiency, leveraging AI
              to handle key tasks:
            </p>
            <li>
              {" "}
              AI Data Director
              <p className="text-primary text-base  pb-4 title">
                Centralizes and analyzes your campaign data to inform strategy
                and optimize decisions.
              </p>{" "}
            </li>
            <li>
              AI Social Media Manager
              <p className="text-primary text-base  pb-4 title">
                {" "}
                Crafts and schedules engaging social media content, boosting
                your online presence and expanding your reach.
              </p>{" "}
            </li>
            <li>
              AI Speech Writer
              <p className="text-primary text-base  pb-4 title">
                Generates persuasive speeches and talking points tailored to
                your campaign’s message, ensuring consistency and impact.
              </p>
            </li>
            <li>
              AI Opposition Researcher
              <p className="text-primary text-base  pb-4 title">
                Conducts thorough research on opponents to inform your strategy,
                giving you a competitive edge.
              </p>
            </li>
            <li>
              AI Donor Manager
              <p className="text-primary text-base   title">
                Identifies and engages potential donors, maximizing your
                fundraising efforts and ensuring financial sustainability.
              </p>
            </li>
          </div>
          <div className="mx-4 md:mx-0">
            <img
              style={{ height: "680px", width: "750px", objectFit: "cover" }}
              src={picture}
              alt="picture"
            />
          </div>
        </div> */}

        <div>
          <div className="w-full bg-white">
            <div className="max-w-7xl mx-auto px-10 md:px-20 py-20">
              <h1 className="text-black text-xl title title pb-3">
                Virtual Campaign Team
              </h1>
              <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
              <p className="text-primary text-base  pb-10   title">
                Elevate your political campaign with our comprehensive AI-driven
                Virtual Campaign Team. This suite of tools empowers you to run a
                full-scale campaign with precision and efficiency, leveraging AI
                to handle key tasks:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                {ourWorkData.map((item, index) => {
                  return (
                    <div key={index} className="workCard relative">
                      <div class="image-box">
                        <img src={item.picture} alt="Unsplashed Random" />
                        <h1 className="px-4 hidden absolute bottom-20 text-black title text-2xl">
                          {item.title}
                        </h1>
                        <p className="px-4 hidden absolute bottom-1 text-black  text-base">
                          {item.desc}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* -------------------  Marketing AI for Tailored Content ------------------- */}

        <div className="flex flex-col md:flex-row pb-8">
          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">
              Contract Assistant
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary text-base  pb-4 title">
              Simplify your legal processes with AI by receiving detailed
              summaries and comparisons of technical documents, saving you time
              and reducing the need for constant legal consultations. For
              example: "Summarize the lease terms for my rental contract.""What
              are the differences between drafts 1 and 2 of this city
              ordinance?"By joining our beta program, you’ll have the
              opportunity to be an early adopter of these innovative AI
              solutions at special introductory pricing. With Astra Intel,
              you’re not just integrating AI—you’re revolutionizing the way your
              organization harnesses data.
            </p>
          </div>
          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">
              Marketing AI for Tailored Content
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary title text-base  pb-4">
              Move beyond generic AI tools like ChatGPT. Our AI can be trained
              on your specific content, enabling your marketing team to generate
              highly personalized and effective communications. For example:
              "Write an email subject line based on last quarter’s
              best-performing emails.""Give me ten ideas for new social media
              posts based on this week’s real estate news."
            </p>
          </div>
        </div>

        {/* ------------ Contract Assistant ---------------- */}

        {/* <div className="flex flex-col md:flex-row">
          <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
            <h1 className="text-black text-xl title pb-6">
              Contract Assistant
            </h1>
            <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
            <p className="text-primary text-base  pb-4 title">
              Simplify your legal processes with AI by receiving detailed
              summaries and comparisons of technical documents, saving you time
              and reducing the need for constant legal consultations. For
              example:
            </p>
            <li className="text-primary text-base  pb-4 title">
              "Summarize the lease terms for my rental contract."
            </li>
            <li className="text-primary text-base  pb-4 title">
              "What are the differences between drafts 1 and 2 of this city
              ordinance?"
            </li>
            <p className="text-primary text-base  pb-4 title">
              By joining our beta program, you’ll have the opportunity to be an
              early adopter of these innovative AI solutions at special
              introductory pricing. With Astra Intel, you’re not just
              integrating AI—you’re revolutionizing the way your organization
              harnesses data.
            </p>
          </div>
          <div className="mx-4 md:mx-0">
            <img
              style={{ height: "450px", width: "750px", objectFit: "cover" }}
              src={picture}
              alt="picture"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurWork;
