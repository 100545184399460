import React from 'react'
import Index from '../components/work'
const work = () => {
    return (
        <div>
            <Index />
        </div>
    )
}

export default work
