export const leaderShipData = [
  {
    picture: require("../images/sam-bourton-exco.webp").default,
    userName: "Sam Bourton",
    designation: "Founder & Chief Technology Officer",
    description:
      "Sam is a published author and seasoned speaker, with 20 years’ experience in complex visual analytics and decision-support systems. Before co-founding QuantumBlack, he led the design and development of F1 trackside management software, developed a global collaboration platform for Bioinformatics researchers, and built a simulation platform used to analyse election results.",
    descriptionB:
      "Sam leads QuantumBlack’s technology and asset development strategy. He also founded our talented engineering team that includes software developers, analytics engineers, data engineers, creative web developers and data visualisation designers.",
  },
  {
    picture: require("../images/jacomo-corbo-exco.webp").default,
    userName: "Jacomo Corbo, PhD",
    designation: "Founder & Chief Scientist",
    description:
      "Jacomo focuses on the problem of applying AI and other technology to improving the quality and scalability of QuantumBlack’s client delivery. He is passionate about helping organizations adopt AI at scale to radically improve their performance.",
    descriptionB:
      "He is also a Senior Fellow at the Wharton School at the University of Pennsylvania and was the Canada Research Chair in Information and Performance Management at the University of Ottawa between 2011 and 2015. Between 2006 and 2008, he was the Chief Race Strategist for the Renault F1 Team. He also holds a PhD in Computer Science from Harvard University.",
  },
  {
    picture: require("../images/jeremy-palmer-exco.webp").default,
    userName: "Jeremy Palmer",
    designation: "CEO",
    description:
      "Jeremy joined QuantumBlack as CEO in 2014, and has overseen the company’s rapid growth and emergence as a global leader in the use of advanced analytics to drive performance improvement and innovation from data. He is also a Senior Partner of McKinsey.",
    descriptionB:
      "Jeremy has decades of wide ranging leadership experience, from the military to global financial services firms in Europe and Asia, to early stage digital and technical venture capital. He is deeply committed to the principles of continuous learning and reinvention, and the importance of strong culture and organisational values when growing successful teams. Jeremy is also currently Chairman of Day One Trust, which prepares young people for careers in the creative industries, and a Trustee of The Art Fund.",
  },
  {
    picture: require("../images/alex-singla-exco.webp").default,
    userName: "Alex Singla",
    designation: "Co-Lead of North America",
    description:
      "Alex co-leads QuantumBlack North America. He is also a Senior Partner with McKinsey & Company where he is the global leader of McKinsey Analytics and a global leader within McKinsey Transformation. In these roles, he helps companies transform by deploying digital and analytics solutions and ensuring these solutions go from pilot to full-scale adoption and scaling.",
    descriptionB:
      "He passionately embeds change management and capability building as core elements to every transformation to ensure sustainable and step-change improvements in growth, effectiveness, cost, profitability, and customer experience.",
  },
  {
    picture: require("../images/jules-seeley-exco.webp").default,
    userName: "Jules Seeley",
    designation: "Co-Lead of North America",
    description:
      "Jules co-leads QuantumBlack North America . He also leads McKinsey’s global advanced analytics and digital work in the Travel, Transport and Logistics Practice. In his nearly two decades with McKinsey, he has developed deep experience serving transport and industrial clients in areas such as operational efficiency and transformation, regulatory strategy, talent, capital investment programmes, maintenance, commercial sales and loyalty, and digital transformation across the private and public sector.",
    descriptionB:
      "He is supporting many of these organisations to deploy digital and advanced analytics approaches and capabilities to bring about fundamental changes to improve decision making and performance.",
  },
  {
    picture: require("../images/helen-mayhew-exco.webp").default,
    userName: "Helen Mayhew",
    designation: "Partner, Australia",
    description:
      "Helen brings her analytical skills to diverse projects, ranging from commercial expansion to front-line team performance optimisation. She has guided leading organisations on their journeys, advising top executives and leaders on the potential for insight and innovation through advanced analytics.",
    descriptionB:
      "Helen has a particular interest is building clients’ analytical organisations and skills, having honed these herself founding the McKinsey’s Analytics Academy. She is also responsible for progressing applied ethical and sustainable AI across our projects.",
  },
  {
    picture: require("../images/tom-meakin-exco.webp").default,
    userName: "Tom Meakin",
    designation: "Chief Operating Officer, Europe",
    description:
      "As Chief Operating Officer of Europe, Tom is responsible for growing QuantumBlack around the world to keep pace with client demands, defining its strategy with McKinsey, and sustaining its performance and health as the company scales.",
    descriptionB:
      "Tom is also a Partner at McKinsey and leads its Consumer Tech & Media practice in Europe. He uses analytics to help clients with their most critical strategic and commercial issues including building new businesses powered by data, launching sophisticated marketing campaigns and exploring potential adjacencies. He leads McKinsey’s research on CEO excellence, which uses data to understand the link between executive actions and company performance.",
  },
  {
    picture: require("../images/tim-fountaine-exco.webp").default,
    userName: "Tim Fountaine",
    designation: "Head of Australia, New Zealand",
    description:
      "Tim works with a range of public and private organisations on matters related to advanced analytics, machine learning, and artificial intelligence. Leading QuantumBlack Australia and New Zealand, alongside being a partner at McKinsey, the scope of Tim’s work spans countries and industries giving him a truly global perspective on the latest issues facing companies.",
    descriptionB:
      "As part of McKinsey and QuantumBlack’s Analytics Academy, Tim has taught over 1,000 executives the fundamentals of building an AI-powered organisation. His writing on this topic was featured on the cover of the Harvard Business Review. Prior to joining McKinsey, Tim was a physician and holds a doctorate in neuroscience from the University of Oxford, where he was a Commonwealth Scholar.",
  },
  {
    picture: require("../images/pepe-cafferata-exco.webp").default,
    userName: "Pepe Cafferata",
    designation: "Head of Latin America",
    description:
      "Pepe drives QuantumBlack’s growth in Latin America. He is passionate about helping clients unlock significant value through analytics and data, and in supporting leading organisations through their advanced analytics transformation journey.",
    descriptionB:
      "Pepe is a Partner at McKinsey & Company, where he has worked in Latin America, United States and Europe serving financial institutions and other companies on a variety of strategic, analytics, commercial, operational and technology issues. He also has 15 years of leadership experience in global financial services firms, where he held regional and country-level Executive Committee positions in areas such as strategy, analytics, CRM, marketing, digital channels and customer experience.",
  },
  {
    picture: require("../images/kate-smaje-exco.webp").default,
    userName: "Kate Smaje",
    designation: "Senior Advisor",
    description:
      "As a Senior Advisor, Kate draws upon her extensive experience with McKinsey, where she leads the digital consumer work across Europe. She helps retailers and consumer-facing companies to capture the opportunities afforded by new growth platforms, rethinking their consumer-engagement models and improving organisational performance.",
    descriptionB:
      "Kate has steered numerous initiatives to help clients develop omni-channel operating models, capture new growth opportunities, and adapt for their organisations. A particular focus of Kate’s work is on designing, delivering and turbocharging digital transformations — a crucial imperative as industry dynamics evolve.",
  },
  {
    picture: require("../images/kat-shenton-exco.webp").default,
    userName: "Kat Shenton",
    designation: "Head of Marketing & Communications",
    description:
      "Kat Shenton is Head of Marketing & Communications globally at QuantumBlack. She oversees all internal and external communications as well as working closely with the QuantumBlack Labs team to shape the Firm’s open source initiatives and engagement with the global developer and tech community. ",
    descriptionB:
      "Kat plays an integral role in introducing QuantumBlack to a variety of audiences, from data and design professionals, to clients and university students. Through Kat’s expertise and support, QuantumBlack consistently highlights the power of data and the need for a fair, ethical and accurate data analytics industry. With more than 15 years’ experience in B2B communications and marketing agencies, Kat has helped established and emerging brands develop and present their stories. ",
  },
  {
    picture: require("../images/helen-mullings-exco.webp").default,
    userName: "Helen Mullings",
    designation: "Global Chief HR Officer",
    description:
      "Helen is a leading figure in the human resources industry, having led professional development for McKinsey & Company in the UK & Ireland for over 10 years. She is focused on building a unique culture and development environment within QuantumBlack, where talented technical specialists can flourish and attain their very best.",
    descriptionB:
      "Helen is passionate about diversity and regularly speaks and writes on gender diversity issues. She helped grow the McKinsey & Company UK office into a flagship for women’s representation and progression and is committed to ensuring that QuantumBlack follows a similar path, as it scales.",
  },
];

export const ourWorkData = [
  {
    // picture: require("../images/cs-pm-01-1440.jpg").default,
    picture: require("../images/data_director.jpeg").default,

    title: "AI Data Director",
    desc: "Centralizes and analyzes your campaign data to inform strategy and optimize decisions.",
  },
  {
    // picture: require("../images/cs-ao-2440.jpg").default,
    picture: require("../images/social_media_manager.jpeg").default,
    title: "AI Social Media Manager",
    desc: "Crafts and schedules engaging social media content, boosting your online presence and expanding your reach.",
  },

  {
    // picture: require("../images/cs-am-01-2440.jpg").default,
    picture: require("../images/speech_writer.jpeg").default,
    title: "AI Speech Writer",
    desc: "Generates persuasive speeches and talking points to your campaign’s message, ensuring consistency.",
  },
  // {
  //   picture: require("../images/cs-ms-02-2440.jpg").default,
  //   title: "Location technology",
  //   desc: "Formula one Sport",
  // },
  {
    picture: require("../images/opposition_researcher.jpeg").default,
    title: "AI Opposition Researcher",
    desc: "Conducts thorough research on opponents to inform your strategy, giving you a competitive edge.",
  },
  {
    picture: require("../images/donor_manager.jpeg").default,
    title: "AI Donor Manager",
    desc: "Identifies and engages potential donors, maximizing your fundraising efforts and ensuring sustainability.",
  },
  // {
  //   picture: require("../images/cs-pm-01-1440.jpg").default,
  //   title: "Clinical trials",
  //   desc: "Healthcare Pharmaceutical",
  // },
  // {
  //   picture: require("../images/cs-tn-01-alt-lines.jpg").default,
  //   title: "Network management",
  //   desc: "Advanced Industries Telecom Networks",
  // },
  // {
  //   picture: require("../images/cs-pf-01-2440.jpg").default,
  //   title: "Injury prediction",
  //   desc: "Premiership Football Sport",
  // },
  // {
  //   picture: require("../images/cs-hc-08-2440.jpg").default,
  //   title: "Screening models",
  //   desc: "Healthcare Medical Research",
  // },
];
