import React from "react";

const Header = () => {
  return (
    <div className="work-header">
      <div className="max-w-7xl mx-auto px-10 md:px-20">
        <h1 className="title text-white md:max-w-2xl text-5xl md:text-6xl  pt-48 pb-10">
          {/* Spearheading end-to-end transformation. */}
          Full Tier Service in the Age of AI
        </h1>
        <p className=" h-1 bg-blue-500 w-24 mb-14"></p>
        <p className="title text-xl text-white md:max-w-md">
          {/* We partner with some of the world’s most advanced organisations in
          their journey to improve performance. */}
          We can help you with all parts of your data and AI journey
        </p>
      </div>
    </div>
  );
};

export default Header;
