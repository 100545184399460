import React from 'react'
import Layout from "../../Layout";
import Header from "./Header";
import OurWork from "./OurWork";
const Index = () => {
    return (
        <Layout>
            <Header />
            <OurWork />
        </Layout>
    )
}

export default Index
